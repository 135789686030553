if (typeof String.prototype.endsWith !== 'function') {
  Object.defineProperty(String.prototype, 'endsWith', {
    value: function (search, this_len) {
      if (this_len === undefined || this_len > this.length) {
        this_len = this.length;
      }
      return this.substring(this_len - search.length, this_len) === search;
    }
  });
}

if (typeof String.prototype.startsWith !== 'function') {
  Object.defineProperty(String.prototype, 'startsWith', {
    value: function (search, rawPos) {
      // eslint-disable-next-line no-bitwise
      const pos = rawPos > 0 ? rawPos | 0 : 0;
      return this.substring(pos, pos + search.length) === search;
    }
  });
}

if (typeof String.prototype.includes !== 'function') {
  Object.defineProperty(String.prototype, 'includes', {
    value: function (search, start) {
      'use strict';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((search as any) instanceof RegExp) {
        throw TypeError('first argument must not be a RegExp');
      }
      if (start === undefined) {
        start = 0;
      }
      return this.indexOf(search, start) !== -1;
    }
  });
}

if (typeof String.prototype['replaceAll'] !== 'function') {
  Object.defineProperty(String.prototype, 'replaceAll', {
    value: function (str, newStr) {
      return this.replace(new RegExp(str, 'g'), newStr);
    }
  });
}

if (typeof String.prototype['padStart'] !== 'function') {
  Object.defineProperty(String.prototype, 'padStart', {
    value: function (targetLength, padString) {
      // floor if number or convert non-number to 0;
      // eslint-disable-next-line no-bitwise
      targetLength = targetLength >> 0;
      padString = String(typeof padString !== 'undefined' ? padString : ' ');
      if (this.length > targetLength) {
        return String(this);
      } else {
        targetLength = targetLength - this.length;
        if (targetLength > padString.length) {
          // append to original to ensure we are longer than needed
          padString += padString.repeat(targetLength / padString.length);
        }
        return padString.slice(0, targetLength) + String(this);
      }
    }
  });
}

if (typeof String.prototype['padEnd'] !== 'function') {
  Object.defineProperty(String.prototype, 'padEnd', {
    configurable: true,
    writable: true,
    value: function (targetLength, padString) {
      // floor if number or convert non-number to 0;
      // eslint-disable-next-line no-bitwise
      targetLength = targetLength >> 0;
      padString = String(typeof padString !== 'undefined' ? padString : ' ');
      if (this.length > targetLength) {
        return String(this);
      } else {
        targetLength = targetLength - this.length;
        if (targetLength > padString.length) {
          // append to original to ensure we are longer than needed
          padString += padString.repeat(targetLength / padString.length);
        }
        return String(this) + padString.slice(0, targetLength);
      }
    }
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).global = window;
